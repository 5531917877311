import { StatusCodes } from "http-status-codes";
import { SutroError } from "sutro-common";
import { Replace } from "sutro-common/mapped-types/replace";
import { AnyObject } from "sutro-common/object-types";
import { SutroApiErrorValues } from "sutro-common/sutro-api/types";

export enum STUDIO_HTTP_ERROR_TYPES {
  UnknownError = -1,
  AbortedRequest = 0,
}

type ErrorTypes = STUDIO_HTTP_ERROR_TYPES | StatusCodes;

type StudioHttpErrorContext = Replace<
  AnyObject,
  {
    serverError?: SutroApiErrorValues;
    request?: { url: string; method: string };
  }
>;
export class StudioHttpError extends SutroError {
  context: StudioHttpErrorContext & {
    status: ErrorTypes;
  };
  constructor(
    public readonly status: ErrorTypes,
    message: string,
    options?: {
      cause?: Error;
      context?: StudioHttpErrorContext;
    }
  ) {
    const passedContext = options?.context ?? {};
    const context = { ...passedContext, status };
    super(message, { ...options, context });
    this.context = context;
    Object.setPrototypeOf(this, StudioHttpError.prototype);
    this.name = "StudioHttpError";
  }
}

/**
 * @param {Error} error - The error instance to test if it is in fact an instance of StudioHttpError
 * @param {ErrorTypes | ErrorTypes[]} errorTypes - The accepted values for this error, for example an HTTP status code, or a `STUDIO_HTTP_ERROR_TYPES`. This is satisfied by an OR with `Array.some`.
 */
export function isStudioHttpError(
  error: Error,
  errorTypes: ErrorTypes | ErrorTypes[]
): error is StudioHttpError {
  if (Array.isArray(errorTypes)) {
    return errorTypes.some((e) => isStudioHttpError(error, e));
  }
  return (
    error instanceof StudioHttpError && error.context.status === errorTypes
  );
}
