import { HEADER_KEYS } from "sutro-common";
import { AuthData } from "sutro-common/auth-data";
import { AnyObject } from "sutro-common/object-types";

import { SutroApi } from ".";
import { Api, FetchOptions, HttpResponse } from "./Api";
import { isStudioHttpError, STUDIO_HTTP_ERROR_TYPES } from "./StudioHttpError";

export class AuthenticatedSutroApi implements Api {
  private _isAuthenticated = true;
  private pendingRefreshedAuthToken: Promise<string> | null = null;

  constructor(private sutroApi: Api) {}

  /**
   * These getters and setters make it so that the auth data is
   * automatically persisted in local storage
   */

  private set authData(authData: AuthData | null) {
    if (authData === null) {
      SutroApi.clearAuthData();
    } else {
      SutroApi.updateAuthData(authData);
    }
  }

  private get authData(): AuthData | null {
    return SutroApi.getAuthData();
  }

  /**
   * If a function gets passed an already authorized API, we can make life easier for them
   * by making this a no-op
   */
  authenticate(): Api {
    return this;
  }

  isAuthenticated() {
    return this._isAuthenticated;
  }

  /**
   * This method gets a valid auth token
   *
   * If the current one is valid, then that gets returned.
   *
   * Otherwise, we refresh the token and return the new one.
   *
   * If the authData is lost somehow, then this method raises an event to force a clean logout
   * and returns the empty string
   */
  private async getAuthToken(): Promise<string> {
    const authData = this.authData;
    if (authData === null) {
      window.dispatchEvent(new CustomEvent("sutro:force-logout"));
      return "";
    } else {
      /**
       * If the token has not expired, return it
       */
      if (authData.authTokenExpiresAt >= Date.now() / 1000) {
        return authData.authToken;
      }

      /**
       * If we're already refreshing the token, return the promise
       * that resolves to the new auth token
       */
      if (this.pendingRefreshedAuthToken !== null) {
        console.log("Returning pending refreshed token");
        return this.pendingRefreshedAuthToken;
      }

      /**
       * Otherwise, refresh the token
       */
      const { promise: refreshPromise, resolve } =
        Promise.withResolvers<string>();

      this.pendingRefreshedAuthToken = refreshPromise;

      let tokenRefreshed = false;
      do {
        console.log("Refreshing token");
        const result = await SutroApi.getApi().post<AuthData>(
          "/users/token/refresh",
          {
            refreshToken: authData.refreshToken,
          }
        );

        if (result.isRight()) {
          this.authData = result.getRight();
          resolve(result.getRight().authToken);
          tokenRefreshed = true;
        } else {
          const error = result.getLeft();
          if (
            isStudioHttpError(error, STUDIO_HTTP_ERROR_TYPES.AbortedRequest)
          ) {
            continue;
          }

          window.dispatchEvent(new CustomEvent("sutro:force-logout"));
          resolve("");
          tokenRefreshed = true;
        }
      } while (
        // This will only loop if the request is aborted.
        !tokenRefreshed
      );

      this.pendingRefreshedAuthToken = null;
      return refreshPromise;
    }
  }

  async get<R>(
    path: string,
    {
      payload,
      options,
    }: {
      payload?: URLSearchParams | null;
      options?: FetchOptions;
    } = {
      payload: null,
      options: {},
    }
  ): Promise<HttpResponse<string | R | Blob>> {
    const headers = options?.headers ?? new Headers();
    const authToken = await this.getAuthToken();

    headers.set(HEADER_KEYS.AUTHORIZATION, authToken);

    return this.sutroApi.get<R>(path, {
      payload: payload ?? undefined,
      options: { ...options, headers },
    });
  }

  async post<R>(
    path: string,
    payload?: Blob | FormData | AnyObject | null,
    options: FetchOptions = {}
  ): Promise<HttpResponse<string | R | Blob>> {
    const headers = options.headers ?? new Headers();
    const authToken = await this.getAuthToken();
    headers.set(HEADER_KEYS.AUTHORIZATION, authToken);

    return this.sutroApi.post(path, payload ?? null, {
      ...options,
      headers,
    });
  }

  async put<R>(
    path: string,
    payload?: Blob | FormData | AnyObject | null,
    options: FetchOptions = {}
  ): Promise<HttpResponse<string | R | Blob>> {
    const headers = options.headers ?? new Headers();
    const authToken = await this.getAuthToken();
    headers.set(HEADER_KEYS.AUTHORIZATION, authToken);

    return this.sutroApi.put(path, payload ?? null, {
      ...options,
      headers,
    });
  }

  async patch<R>(
    path: string,
    payload?: Blob | FormData | AnyObject | null,
    options: FetchOptions = {}
  ): Promise<HttpResponse<string | R | Blob>> {
    const headers = options.headers ?? new Headers();
    const authToken = await this.getAuthToken();
    headers.set(HEADER_KEYS.AUTHORIZATION, authToken);

    return this.sutroApi.patch(path, payload ?? null, {
      ...options,
      headers,
    });
  }

  async delete<R>(
    path: string,
    options: FetchOptions = {}
  ): Promise<HttpResponse<R>> {
    const headers = options.headers ?? new Headers();
    const authToken = await this.getAuthToken();
    headers.set(HEADER_KEYS.AUTHORIZATION, authToken);

    return this.sutroApi.delete(path, {
      ...options,
      headers,
    });
  }
}
